import logo from "./assets/loading.png";
import "./App.css";
import { range } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faStar } from "@fortawesome/free-solid-svg-icons";
import Consultation from "./Consultation";
import { DATA } from "./DATA";
import { BrowserRouter as Router, Switch, Route, a } from "react-router-dom";
import Logo from "./Logo";
import { Hasha } from "react-router-hash-link";
function Home() {
  return (
    <div className="App">
      <header className="App-header-container">
        <div className="App-header">
          <div className="App-header-logobox">
            <Logo />

            <text className="Logo-name">AstroShrine</text>
          </div>
          <div className="App-header-child">
            <a>
              <text className="headertext">Home</text>
            </a>
            <a href={"/#contactus"}>
              <text className="headertext">Contact Us</text>
            </a>
          </div>
        </div>
      </header>

      <div className="body">
        <div className="startlayout">
          <button className="chatbutton">
            <text>Chat with Astrologer</text>
          </button>
          <text>available balance: ₹ 0</text>
          <div className="row">
            <button className="outline-recharge">
              <text>Recharge</text>
            </button>
          </div>
        </div>
        <div className="cardlayout">
          {DATA.map((item, index) => (
            <div key={index} className="card">
              <div className="cardchild">
                <div className="column">
                  {/* <img src={item?.img} className="cardimage" alt="image" /> */}
                  <Logo height={80} width={80} />
                  <div className="iconbox">
                    {range(5).map((index) => (
                      <FontAwesomeIcon
                        key={index}
                        icon={faStar}
                        size={10}
                        color="#ede2cc"
                      />
                    ))}
                  </div>
                  <text>{item.orders} orders</text>
                </div>
                <div className="textArea">
                  <text className="cardTextName">{item.name}</text>
                  <text>{item.speciality}</text>
                  <text>{item.lang}</text>
                  <text>Exp: {item.exp} years</text>
                  <text>₹{item.rate}/min </text>
                </div>
              </div>
              <div className="chatColumn">
                <button className="chat">{`Chat`}</button>
              </div>
            </div>
          ))}
        </div>
        <br></br>
        <br></br>
        <Consultation />
        <br></br>
        <br></br>
      </div>
      <div></div>
      <footer className="footer">
        <div className="footerClass">
          <text className="labelText2">About AstroShrine</text>
          <text>
            AstroShrine is the best astrology website for online Astrology
            predictions. Talk to Astrologer on call and get answers to all your
            worries by seeing the future life through Astrology Kundli
            Predictions from the best Astrologers from India. Get best future
            predictions related to Marriage, love life, Career or Health over
            call, chat, query or report.
          </text>
          <text className="labelText2">Corporate Info</text>
          <a href={"/TermsNConditions"} className="footerText">
            Terms & Conditions
          </a>
          <a href={"/Privacy"} className="footerText">
            Privacy Policy
          </a>
          <a href={"/TermsNConditions/#hey"} className="footerText">
            Refund & cancellation Policy
          </a>
          <div id="contactus">
            <text className="labelText2">Contact Us</text>
          </div>
          <text className="footerText">
            <FontAwesomeIcon icon={faEnvelope} size={15} color="white" /> Email
            ID: support@astroshrine.com
          </text>
        </div>
      </footer>
    </div>
  );
}

export default Home;
