export const DATA = [
    {
      id: 1,
      name: `Roopak Tripathi`,
      lang: [`English`, ` | `, `Hindi`],
      speciality: [`Vedic`, `Tarot`],
      exp: 1,
      rate: `20`,
      orders: 1234,
      img: require("./assets/LOgo1.png"),
    },
    {
      id: 2,
      name: `Ishan Tripathi`,
      lang: [`English`, ` | `, `Hindi`],
      speciality: [`Vedic`, `Tarot`],
      exp: 1,
      rate: `20`,
      orders: 1234,img: require("./assets/LOgo1.png"),
    },
    {
      id: 3,
      name: `Vaibhav Patil`,
      lang: [`English`, ` | `, `Hindi`],
      speciality: [`Vedic`, `Tarot`],
      exp: 1,
      rate: `20`,
      orders: 1234,
      img: require("./assets/LOgo1.png"),
    },
    {
      id: 4,
      name: `Mivaan Sahu`,
      lang: [`English`, ` | `, `Hindi`],
      speciality: [`Vedic`, `Tarot`],
      exp: 1,
      rate: `20`,
      orders: 1234,
      img: require("./assets/LOgo1.png"),
    },
    {
      id: 5,
      name: `Vinay`,
      lang: [`English`, ` | `, `Hindi`],
      speciality: [`Vedic`, `Tarot`],
      exp: 1,
      rate: `20`,
      orders: 1234,
      img: require("./assets/LOgo1.png"),
    },
    {
      id: 6,
      name: `Suraj`,
      lang: [`English`, ` | `, `Hindi`],
      speciality: [`Vedic`, `Tarot`],
      exp: 1,
      rate: `20`,
      orders: 1234,
      img: require("./assets/LOgo1.png"),
    },
    {
      id: 7,
      name: `Shashwat`,
      lang: [`English`, ` | `, `Hindi`],
      speciality: [`Vedic`, `Tarot`],
      exp: 1,
      rate: `20`,
      orders: 1234,
      img: require("./assets/LOgo1.png"),
    },
    {
      id: 8,
      name: `Nikhil`,
      lang: [`English`, ` | `, `Hindi`],
      speciality: [`Vedic`, `Tarot`],
      exp: 1,
      rate: `20`,
      orders: 1234,
      img: require("./assets/LOgo1.png"),
    },
    {
      id: 9,
      name: `Nikita`,
      lang: [`English`, ` | `, `Hindi`],
      speciality: [`Vedic`, `Tarot`],
      exp: 1,
      rate: `20`,
      orders: 1234,
      img: require("./assets/LOgo1.png"),
    },
    {
      id: 10,
      name: `Kanishka Singh`,
      lang: [`English`, ` | `, `Hindi`],
      speciality: [`Vedic`, `Tarot`],
      exp: 1,
      rate: `20`,
      orders: 1234,
      img: require("./assets/LOgo1.png"),
    },
  ];