import "./App.css";
function Consultation() {
  return (
        <div className="descriptionBox">
        <text className="labelText">How Chatting with an astrologer can help you?</text>
        <text>Best online astrology consultation</text>
        <text className="justify">
          There’s literally no point in overthinking about your concerns when
          the solution to them is just a call away. Astrotalk brings to you a
          pool of over 500+ astrologers with whom you can contact on chat and
          share with them your problems across all aspects of life including
          love, finance, Vastu, career, luck, marriage and so on. Simply put,
          life is a cocktail of highs and lows, and while we enjoy the good
          times, the bad times, though they teach us a lot, can also leave us
          worried, depressed and sad. Such times can also hamper the
          relationship we share with other people in our lives, and we are sure
          that you wouldn’t want that to happen for yourself. Astrotalk, with
          its exceptional astrology consultant services, caters to you the
          solutions to problems that you might be facing in the aforementioned
          aspects of life. Some problems in life are simply the consequence of
          astronomical factors that make it to our fate since the time we are
          born. To specify, there are some predefined dashas that each one of us
          has to go through in life. These dashas, like for example, Shani
          Dasha, or Rahu Dasha, bring all sorts of problems in your life. These
          problems can lead to loss of confidence, wealth, relationship and
          other such woes. Thus it is in the best interest to take necessary
          actions through which such problems can be averted. And astrologers
          have such solutions for you. At Astrotalk, we have a pool of learned
          astrologers with vast experience across various astrology domains like
          Vedic astrology, Numerology, Tarot reading, Reiki healing, Nandi
          astrology, Vastu and so on. You can connect with these astrologers and
          chat with them and ask for solutions to your problems. Astrologers use
          their experience and astrological knowledge to guide you and help you
          with the best solutions. Meanwhile, astrology is not only about
          solving problems. It also works in many other areas, like if you are
          planning a wedding and want to find the shubh muhurat for marriage,
          simply, chat with an astrologer and you will have your answer.
          Wondering what to name your baby or when to host the mundan ceremony
          for him/her? Expert astrologers consultation can help here too. Or
          simply if you are planning to buy a gemstone and wondering what
          gemstone is made for your rashi? In such circumstances too, chatting
          with the astrologer can help you find what gemstone would suit your
          current situation. AstroTalk provides you with the facility to chat
          with astrologer online free and paid in both ways. To find us online,
          you just need to search online chat with astrologer or free astro chat
          or chat with astrologer online free, talk to astrologer online, and
          chat with astrologer near me. The aim of our services is to give 100%
          satisfaction to the users. We offer you many free services as well
          like free kundali and match making.
        </text>
        </div>
  );
}

export default Consultation;
