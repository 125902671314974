import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from "./Home";
import TermsNConditions from "./TermsNConditions";
import Privacy from "./Privacy";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/Privacy" element={<Privacy/>} />
          <Route
            exact path="/TermsNConditions"
            element={<TermsNConditions />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
